.App {
  text-align: left;
  padding: 50px;
  font-family: "Palatino Linotype", "Times New Roman", serif;
  font-size: 14px;
}

.syllable {
  padding: 0;
}
